import React, { useState } from "react";
import Navbar from './nav';
import Footer from './footer';
import './css/contact.css';
import Call from './images/call.svg'
import Visit from './images/3rd.svg';
import Email from './images/second.svg'
import axios from 'axios'; // Import Axios

function ContactUs() {
    // Define state variables to store form input values
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            phone: phone,
            message: message,
        };

        axios.post('https://champsfin.com/champs/contact.php', formData)
            .then((response) => {
                setSuccessMessage("Form submitted successfully!");
                setErrorMessage("");
                setIsFormSubmitted(true);
                setTimeout(() => {
                    setIsFormSubmitted(false);
                    setSuccessMessage('');
                }, 3000);
                console.log(response.data);
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage("");
                console.error(error);
            });
    };
    return (
        <div>
            <Navbar />
            <div className="contact-container">
                <div className="contact-container-1">
                    <div className="contact-card-call">
                        <div className="contact-card-content">
                            <img src={Call} alt="Image Alt Text" />
                            <h2>Give us a call</h2>
                            <p>09044000008, 08128042840, 09065556644</p>
                        </div>
                    </div>

                    <div className="contact-card-email">
                        <div className="contact-card-content">
                            <img src={Email} alt="Image Alt Text" />
                            <h2>Send Us An Email</h2>
                            <p>hello@champsfin.com</p>
                        </div>
                    </div>

                    <div className="contact-card-visit">
                        <div className="contact-card-content">
                            <img src={Visit} alt="Image Alt Text" />
                            <h2>Visit the Office</h2>
                            <p>741 Adeola Hopewell St. Victoria Island, Lagos State.</p>
                        </div>
                    </div>
                </div>

                <div className={`contact-card ${isFormSubmitted ? 'hidden' : ''}`}>
                    <h2 className="contact-text-contact">Contact Form</h2>
                    {/* Display success message */}
                    {successMessage && <div className="success-message">{successMessage}</div>}

                    {/* Display error message */}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <form onSubmit={handleSubmit} method="POST">
                        <div className="form-group">
                            <label htmlFor="name">Full Name</label>
                            <input type="text" id="name" name="name" placeholder="Enter your full name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" id="email" name="email" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone Number</label>
                            <input type="tel" id="phone" name="phone" placeholder="Enter your mobile number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <button type="submit">Submit</button>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
