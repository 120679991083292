import React, { useState, useEffect } from "react";
import Navbar from "./nav"; // Update the path as needed
import Footer from "./footer"; // Update the path as needed
import "./css/loan.css";
import MoneyBox from './images/moneybox.svg';
import Family from './images/family.svg';
import Yellow from './images/yellowman.svg';
import Overlay from './images/card-imageback.png';
import FoodPack from './images/ounje.png';
import FiveHundred from './images/fivehundred.svg';
import MoneyJoin from './images/moneyjoin.png';
import SmallJoin from './images/Group 6097.svg'
import Cancel from './images/cancel.png';
import axios from 'axios'; // Import Axios




function Loan() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenJwt, setIsModalOpenJwt] = useState(false);
    const [loanAmount, setLoanAmount] = useState('');
    const [selectedBank, setSelectedBank] = useState('option1');
    const [result, setResult] = useState({
        totalAmountToRepay: 0,
        interestAmount: 0,
        interestRate: 0,
    });
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModalJwt = () => {
        setIsModalOpenJwt(true);
    };

    // Function to close the modal
    const closeModalJwt = () => {
        setIsModalOpenJwt(false);
    };
    const handleLoanAmountChange = (event) => {
        setLoanAmount(event.target.value);
    };

    const handleBankChange = (event) => {
        setSelectedBank(event.target.value);
    };

    const calculateLoan = () => {

        const interestRates = {
            option2: 3.0, // Globus Bank
            option3: 3.5, // Access Bank
        };


        const interestRate = interestRates[selectedBank] || 0;
        const interestAmount = (parseFloat(loanAmount) * interestRate) / 100;


        const totalAmountToRepay = parseFloat(loanAmount) + interestAmount;

        setResult({ totalAmountToRepay, interestAmount, interestRate });
        // Function to open the modal

    };
    const submitLoanApplication = (e) => {
        e.preventDefault();

        // Create an object with the form data
        const loanForm = {
            fullName,
            email,
            phone,
            amount: parseFloat(amount),
        };

        // Send a POST request to your PHP script
        axios.post('https://champsfin.com/champs/check.php', loanForm)
            .then((response) => {

                setSuccessMessage("Appliation Successfull!");
                setErrorMessage(""); // Clear any previous error message
                setTimeout(() => setSuccessMessage(null), 3000);
                console.log(response.data);
                // Clear the form input fields
                setFullName('');
                setEmail('');
                setPhone('');
                setAmount('');
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage("");
                console.error(error);


            });
    };
    const [interestForm, setInterestForm] = useState({
        fullName: '',
        email: '',
        interest: '',
    });

    const submitInterestForm = (e) => {
        e.preventDefault();

        // Send a POST request to your PHP script
        axios.post('https://champsfin.com/champs/waitlist.php', interestForm)
            .then((response) => {
                setSuccessMessage("Interest Form Submitted Successfully!");
                setErrorMessage(""); // Clear any previous error message
                setTimeout(() => setSuccessMessage(null), 3000);
                console.log(response.data);

                // Clear the second form input fields
                setInterestForm({
                    fullName: '',
                    email: '',
                    interest: '',
                });
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage(""); // Clear any previous success message
                console.error(error);
            });
    };

    return (
        <div >
            <div className="body" >
                <Navbar />
                <div className="loan-hero">
                    <div className="loan-hero-image">
                        <img src={MoneyJoin} alt="Hero Image" />
                    </div>
                    <div className="loan-hero-text">
                        <h1>Unlock Your Financial
                            Potential with Champs Finance</h1>
                        <p>Looking for a financial boost to chase your dreams? Whether it's a personal project, a business venture, or a life-changing opportunity, Champs Finance has you covered with our flexible loan options. We deal a winning hand with competitive rates and hassle-free approval processes.</p>
                        <a href="#family">Explore Loan Options</a>
                    </div>


                </div>
                <div className="loan-hero-small">
                    <div className="loan-hero-text-small">
                        <h1>Unlock Your Financial
                            Potential with Champs Finance</h1>
                        <p>Looking for a financial boost to chase your dreams? Whether it's a personal project, a business venture, or a life-changing opportunity, Champs Finance has you covered with our flexible loan options. We deal a winning hand with competitive rates and hassle-free approval processes.</p>
                        <a href="">Explore Loan Options</a>
                    </div>
                    <div className="loan-hero-image">
                        <img src={SmallJoin} alt="Hero Image" />
                    </div>
                </div>

            </div>
        <div className="backboy">
            <div id="family" className="image-card-container-loan">
                <div className="image-card-loan">
                    <img src={Family} alt="Image 1" style={{
                        background: `linear-gradient(rgba(182, 194, 228, 0.8), rgba(182, 194, 228, 0.8)), url('../images/graphbackground.png') center/cover no-repeat`, width: '100%'   
                    }} />
                    <div className="image-text-container-loan">
                        <h3 className="image-text-loan-h3" >Champs Travel Loan: Your Gateway to Seamless Visa Applications!</h3>
                        <p className="image-text-loan-p-push" >Planning to migrate? Our Champs Travel Loan serves as the perfect proof of funds for your visa applications. We offer funding up to N100 million directly into applicants' bank accounts, all at a highly affordable rate.
                        </p>
                        <p className="image-text-loan-p-push" > Your migration dreams are just a click away! ✈️🌍💰</p>
                        <button className="family-btn" onClick={openModal} >Get Started</button>
                    </div>
                </div>
            </div>
            <div className="image-card-container-loan">
                <div className="image-card-loan">
                    <img src={FoodPack} alt="Image 1" style={{
                        background: `linear-gradient(rgba(231, 171, 202, 0.8), rgba(231, 171, 202, 0.8)), url('../images/graphbackground.png') center/cover no-repeat`,
                        width: '100%'
                    }} />
                    <div className="image-text-container-loan">
                        <h3 className="image-text-loan-h3">Savor the Flavor of Financial Freedom with Champs Food Loan! ✨🌾</h3>
                        <p className="image-text-loan-p">At Champs Finance, we understand the vital role nutrition plays in every life. That's why we've crafted our exclusive Food Loan – a gateway to affordable, wholesome meals for all. Tailored to your salary, our loan categories ensure access to nutritious food, transforming your challenges into opportunities.</p>
                        <h3 className="image-text-loan-h3" style={{ fontSize: `16px` }} >🍲 Why Choose Champs Food Loan?</h3>
                        <div className="image-text-loan-pp">
                            <p > <span style={{ fontWeight: `bold` }}>Tailored Categories:</span>  Matched to your income for accessibility.</p>
                            <p><span style={{ fontWeight: `bold` }}>Convenient Repayment:</span> Spread across three months for ease.</p>
                            <p ><span style={{ fontWeight: `bold` }}>Nutritious Selection:</span> A diverse range of quality food items.</p>
                            <p> <span style={{ fontWeight: `bold` }}>Empowering Lives: </span>Combating hunger, one loan at a time.</p>
                        </div>
                        <div className="image-text-loan-listt">
                            <ul>
                                <li ><span style={{ fontWeight: 'bold' }}>Tailored Categories:</span> Matched to your income for accessibility.</li>
                                <li ><span style={{ fontWeight: 'bold' }}>Convenient Repayment:</span>Spread across three months for ease.</li>
                                <li ><span style={{ fontWeight: 'bold' }}>Nutritious Selection:</span> A diverse range of quality food items.</li>
                                <li ><span style={{ fontWeight: 'bold' }}>Empowering Lives: </span> Combating hunger, one loan at a time.</li>
                            </ul>
                        </div>
                        <h3 className="image-text-loan-p-push" style={{ fontSize: `14px`, lineHeight: `25.6px`, fontWeight: `400` }} >🌱 Don't let financial constraints limit your plate! Click to join the waitlist and embark on your delicious adventure today!</h3>
                        <a className="food-btn" onClick={openModalJwt} >Join Waitlist</a>
                    </div>
                </div>
            </div>
            <div className="image-card-container-loan">
                <div className="image-card-loan">
                    <img src={FiveHundred} alt="Image 1" style={{
                        background: `linear-gradient(rgba(182, 194, 228, 0.8), rgba(182, 194, 228, 0.8)), url('../images/graphbackground.png') center/cover no-repeat`,width: '100%'
                    }} />
                    <div className="image-text-container-loan">
                        <h3 className="image-text-loan-h3" >Welcome to Champs Salary Advance – Your Pathway to Financial Freedom!</h3>
                        <p className="image-text-loan-p" >Are you a hardworking salary earner in need of timely, affordable, and flexible financial support? Look no further! Champs Salary Advance is your trusted companion, designed exclusively for individuals like you.
                            Why Champs Salary Advance?</p>
                        <div className="image-text-loan-list">
                            <ul>
                                <li ><span style={{ fontWeight: 'bold' }}>Timely Assistance:</span> When unexpected expenses arise, we've got your back with swift and timely financial aid.</li>
                                <li ><span style={{ fontWeight: 'bold' }}>Affordable Solutions:</span> Enjoy competitive interest rates tailored to your budget, ensuring financial ease.</li>
                                <li ><span style={{ fontWeight: 'bold' }}>Flexibility at Its Best:</span> Our repayment plans are designed to match your salary cycles, providing ultimate flexibility.</li>
                            </ul>
                        </div>

                        <a className="food-btn" onClick={openModalJwt}>Join Waitlist</a>
                    </div>
                </div>
            </div>
                    </div>
            <div className="header-title"> <h1>Travel Loan Calculator</h1></div>

            <div className="card-form-loan">
               
                <form>
                    <div className="form-group">
                        <label htmlFor="number">Loan Amount</label>
                        <input
                            type="tel"
                            id="number"
                            name="number"
                            placeholder="Enter Loan Amount"
                            value={loanAmount}
                            onChange={handleLoanAmountChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="dropdown">Preferred Bank</label>
                        <select id="dropdown" className="form-select" name="dropdown" value={selectedBank} onChange={handleBankChange}>
                            <option value="option1">Select bank</option>
                            <option value="option2">Globus Bank</option>
                            <option value="option3">Access Bank</option>
                        </select>
                    </div>

                    <button type="button" onClick={calculateLoan}>Check Loan Amount</button>

                </form>
                <div className="card-image-loan">

                    <div className="image-with-overlay-loan">

                        <div className="overlay-loan">
                            <h3 tyle={{ color: '#000', textAlign: 'center', fontWeight: '600' }}>Result Analysis</h3>

                            <ul>
                                <li>
                                    <span>Loan Amount</span>
                                    <h2>₦{parseFloat(loanAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                                </li>

                                <li>
                                    <span>Loan Duration</span>
                                    <h2>1 month</h2>
                                </li>
                                <li>
                                    <span>Interest Rate</span>
                                    <h2>{result.interestRate}%</h2>
                                </li>
                                <li>
                                    <span>Interest Amount monthly</span>
                                    <h2>₦{result.interestAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card">
                <div className="card-content">
                    <p >🚀 Click to Join the Waitlist and Secure Your Financial Peace of Mind! 🚀</p>
                    <p>At Champs, we believe in empowering your dreams and aspirations. Let's embark on this
                        journey to financial freedom together! </p>
                    <p style={{ color: '#BE2860' }}>#ChampsSalaryAdvance #FinancialFreedom #JoinTheWaitlist"</p>
                    <button className="text-learn-more" onClick={openModalJwt} >Join Waitlist</button>
                </div>
            </div>


            <Footer />
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModal} />
                            {/* <button >i=<img></img></button> */}
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Get Champs Travel Loan</h3>
                        </div>
                        {/* Display success message */}
                        {successMessage && <div className="success-message">{successMessage}</div>}

                        {/* Display error message */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <form onSubmit={submitLoanApplication} className="jwm-form-loan">

                            <div>
                                <label htmlFor="fullName">Full name</label>
                                <input type="text"
                                    id="fullName"
                                    placeholder="Enter full name"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input type="email" id="email" placeholder="Enter your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone Number</label>
                                <input type="tel" id="phone number" placeholder="Enter your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="amount">Loan Amount</label>
                                <input type="tel" id="amount" placeholder="Enter loan amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            <div>
                                <button type="submit">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            )}
            {isModalOpenJwt && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModalJwt} />
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Join Our Waitlist</h3>
                        </div>
                        {/* Display success message */}
                        {successMessage && <div className="success-message">{successMessage}</div>}

                        {/* Display error message */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <form onSubmit={submitInterestForm} className="jwm-form-loan">
                            <div>
                                <label htmlFor="fullName">Full name</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter full name"
                                    value={interestForm.fullName}
                                    onChange={(e) => setInterestForm({ ...interestForm, fullName: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your Email Address"
                                    value={interestForm.email}
                                    onChange={(e) => setInterestForm({ ...interestForm, email: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="interest">Interest</label>
                                <select
                                    name="interest"
                                    id="interest"
                                    value={interestForm.interest}
                                    onChange={(e) => setInterestForm({ ...interestForm, interest: e.target.value })}
                                >
                                    <option selected disabled value="">What service are you interested in?</option>
                                    <option value="Food loan">Food loan</option>
                                    <option value="Fx Advisory">Fx Advisory</option>
                                    <option value="Thrift">Thrift</option>
                                </select>
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <button type="submit">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </div>


    );
}

export default Loan;
