import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import Navbar from './nav';
import Footer from './footer';
import ContactUs from './contact';
import About from './about';
import FxAdviosry from './fx';
import Payment from './payment';
import Thrift from './thrift';
import Loan from './loan';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path='about' element={<About/>}/>
        <Route path='navbar' element={<Navbar/>}/>
        <Route path='footer' element={<Footer/>}/>
        <Route path='contact' element={<ContactUs/>}/>
        <Route path='fx' element={<FxAdviosry/>}/>
        <Route path='payment' element={<Payment/>}/>
        <Route path='thrift' element={<Thrift/>}/>
        <Route path='loan' element={<Loan/>}/>
      </Routes>
    </Router>
  );
}

export default App;
