import React, { useState, useEffect } from "react";
import Navbar from "./nav"; // Update the path as needed
import Footer from "./footer"; // Update the path as needed
import "./css/thrift.css";
import MoneyBox from './images/moneybox.svg';
import Hands from './images/joinedhand.svg';
import Yellow from './images/yellowman.svg';
import Overlay from './images/card-imageback.png';
import Robot from './images/robot.svg';
import Lock from './images/lock.svg';
import Percentage from './images/percentage.svg';
import Cancel from './images/cancel.png';
import axios from 'axios';




function Thrift() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenJwt, setIsModalOpenJwt] = useState(false);
    const [fullName, setFullName] = useState(""); // Define fullName and its setter
    const [email, setEmail] = useState(""); // Define email and its setter
    const [phone, setPhone] = useState(""); // Define phone and its setter
    const [location, setLocation] = useState(""); // Define location and its setter
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModalJwt = () => {
        setIsModalOpenJwt(true);
    };

    // Function to close the modal
    const closeModalJwt = () => {
        setIsModalOpenJwt(false);
    };
    const clearForm = () => {
        setFullName("");
        setEmail("");
        setPhone("");
        setLocation("");
    };
    const submitAgentApplication = (e) => {
        e.preventDefault();


        const agentForm = {
            fullName: fullName,
            email: email,
            phone: phone,
            location: location,
        };



        axios
            .post('https://champsfin.com/champs/agent_application.php', agentForm)
            .then((response) => {
                setErrorMessage(""); // Clear any previous error message
                setSuccessMessage('Application submitted successfully.');
                setTimeout(() => setSuccessMessage(null), 3000);
                console.log(response.data);
            })
            .catch((error) => {
                setSuccessMessage(""); // Clear any previous success message
                setErrorMessage('Error submitting the application');
                console.error(error);
            });
    };
    const [interestForm, setInterestForm] = useState({
        fullName: '',
        email: '',
        interest: '',
    });

    const submitInterestForm = (e) => {
        e.preventDefault();

        // Send a POST request to your PHP script
        axios.post('https://champsfin.com/champs/waitlist.php', interestForm)
            .then((response) => {
                setSuccessMessage("Interest Form Submitted Successfully!");
                setErrorMessage(""); // Clear any previous error message
                setTimeout(() => setSuccessMessage(null), 3000);
                console.log(response.data);

                // Clear the second form input fields
                setInterestForm({
                    fullName: '',
                    email: '',
                    interest: '',
                });
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage(""); // Clear any previous success message
                console.error(error);
            });
    };


    return (
        <div>
            <div className="body" >
                <Navbar />
                <div className="heroooo">

                    <div className="heroooo-text">
                        <h1>Introducing Ajo Champs:
                            Your Digital Gateway to
                            Smart Savings!</h1>
                        <p>Ever wanted to embrace the traditional joy of saving, but found it hard within your friend circles? Ajo Champs is here to revolutionize your saving experience! We're digitizing the cherished practice of “Ajo” by making it accessible to everyone, breaking free from the limitations of friendship circles.</p>
                    </div>
                    <div className="heroooo-image">
                        <img src={MoneyBox} alt="Hero Image" />
                    </div>

                </div>


            </div>
            <div className="header-title"> <h1>Why Ajo Champ?</h1></div>
            <div className="image-card-container-thrift">
                <div className="image-card-thrift">
                    <img src={Hands} alt="Image 1" />
                    <div className="image-text-container-thrift">
                        <h3 className="image-text-thrift-h3">Financial Inclusion for All</h3>
                        <p className="image-text-thrift-p">We're bringing the power of savings to your fingertips, ensuring everyone, regardless of their circle, can save smartly and securely.</p>
                    </div>
                </div>
            </div>
            <div className="image-card-container-thrift">
                <div className="image-card-thrift-right">
                    <div className="image-text-container-thrift-right">
                        <h3 className="image-text-thrift-h3">Convenience Redefined</h3>
                        <p className="image-text-thrift-p">No more hassle! With Ajo Champs, saving is now effortless, giving you the freedom to focus on your goals while we handle the rest.</p>
                    </div>
                    <img src={Robot} alt="Image 1" />
                </div>
            </div>
            <div className="image-card-container-thrift">
                <div className="image-card-thrift">
                    <img src={Lock} alt="Image 1" />
                    <div className="image-text-container-thrift">
                        <h3 className="image-text-thrift-h3">Flexibility and Security</h3>
                        <p className="image-text-thrift-p">Enjoy unmatched flexibility and rock-solid security, ensuring your hard-earned money is in safe hands</p>
                    </div>
                </div>
            </div>
            <div className="image-card-container-thrift">
                <div className="image-card-thrift-right">
                    <div className="image-text-container-thrift-right">
                        <h3 className="image-text-thrift-h3">High-Interest Earnings:</h3>
                        <p className="image-text-thrift-p">Watch your savings grow! Ajo Champs offers competitive interest rates, making your money work harder for you.</p>
                    </div>
                    <img src={Percentage} alt="Image 1" />
                </div>
            </div>
            <div class="image-container-thrift">

                <div class="image-with-overlay-thrift">
                    <img src={Overlay} alt="Image" />
                    <div class="overlay-thrift">
                        <h2>Join the Revolution – Be a
                            Champ Agent!</h2>
                        <p>Excited to be part of this financial revolution? Become an Ajo Champs
                            Agent and be at the forefront of transforming lives. Click the link below to
                            embark on your journey toward financial empowerment and secure a
                            brighter future for yourself and others.</p>
                        <a className="overlay-learn-more" style={{ fontSize: `14px`}}  onClick={openModal}>Become an agent</a>

                    </div>
                </div>
                <img src={Yellow} alt="Second Image" />

            </div>

            <div className="card">
                <div className="card-content">
                    <p>🚀 Click to Join the Waitlist and Embrace the Future of Payments! 🚀</p>
                    <p>At Champs Finance Payments, we're redefining the way you pay. Get ready to
                        shop smarter, faster, and with tailored convenience</p>
                    <button className="text-learn-more" onClick={openModalJwt} >Join Waitlist</button>
                </div>
            </div>


            <Footer />
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModal} />
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Become An Agent</h3>
                        </div>
                        {/* ... Other modal content ... */}
                        {successMessage && (
                            <div className="success-message">{successMessage}</div>
                        )}

                        {errorMessage && (
                            <div className="error-message">{errorMessage}</div>
                        )}
                        <form onSubmit={submitAgentApplication} className="jwm-form-loan">
                            <div>
                                <label htmlFor="fullName">Full name</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter full name" name="fullName"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your Email Address" name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone Number</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    placeholder="Enter your phone number" name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="location">Location</label>
                                <input
                                    type="text"
                                    id="location"
                                    placeholder="Enter location" name="location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                            <div>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isModalOpenJwt && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModalJwt} />
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Join Our Waitlist</h3>
                        </div>
                        {/* Display success message */}
                        {successMessage && <div className="success-message">{successMessage}</div>}

                        {/* Display error message */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <form onSubmit={submitInterestForm} className="jwm-form-loan">
                            <div>
                                <label htmlFor="fullName">Full name</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter full name"
                                    value={interestForm.fullName}
                                    onChange={(e) => setInterestForm({ ...interestForm, fullName: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your Email Address"
                                    value={interestForm.email}
                                    onChange={(e) => setInterestForm({ ...interestForm, email: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="interest">Interest</label>
                                <select
                                    name="interest"
                                    id="interest"
                                    value={interestForm.interest}
                                    onChange={(e) => setInterestForm({ ...interestForm, interest: e.target.value })}
                                >
                                    <option selected disabled value="">What service are you interested in?</option>
                                    <option value="Food loan">Food loan</option>
                                    <option value="Fx Advisory">Fx Advisory</option>
                                    <option value="Thrift">Thrift</option>
                                </select>
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <button type="submit">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Thrift;
