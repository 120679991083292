import React from "react";
import Navbar from "./nav"; // Update the path as needed
import Footer from "./footer"; // Update the path as needed
import "./css/about.css"; // Import your CSS file for the About page
import Tree from './images/tree.png'
import Mission from './images/mission.svg'
import Vision from './images/vision.svg'
import MoneyGrow from './images/moneygrow.jpg'
import A from './images/letterA.png';
import C from './images/letterC.png';
import H from './images/letterH.png';
import M from './images/letterM.png';
import P from './images/letterP.png';
import S from './images/letterS.png';
import { Link } from 'react-router-dom';

function About() {
    return (
        <div>
            <div className="body" >
                <Navbar />
                <div className="hero">

                    <div className="hero-text">
                        <h1>About Champs Finance</h1>
                        <p>At Champs Finance, we believe in the power of champions.Those who seize opportunities, conquer financial challenges,  and emerge victorious in the world of finance. We're here to empower you on your financial journey, providing a range of top-notch services designed to make you the champion of your financial game.</p>
                    </div>
                    <div className="hero-image">
                        <img src={Tree} alt="Hero Image" />
                    </div>

                </div>

                <div className="new-divs">
                    <div className="mission">
                        <img src={Vision} alt="Hero Image" />
                        <h2>Vision</h2>
                        <p>By revolutionizing finance for a brighter future, we're pioneering transformative solutions and innovative strategies to create a more inclusive and prosperous tomorrow</p>
                    </div>
                    <div className="vision">
                        <img src={Mission} alt="Hero Image" />
                        <h2>Mission</h2>
                        <p>To create and manage value through a combination of empathy, talent and superior technology by providing transparent and convenient solutions for stakeholders across all markets</p>
                    </div>
                </div>

            </div>
            <div className="section-with-background">
                {/* <img src={MoneyGrow} alt="Fixed Image" className="fixed-image" /> */}
                <div className="text-container">

                    <div className="columns above">
                        <h2> <img src={C} alt="" />Customer-Centricity</h2>
                        <p>We prioritize our customers, understanding their unique needs, and delivering exceptional services with a focus on their satisfaction.</p>
                    </div>
                    <div className="columns above">
                        <h2><img src={H} alt="" />Honesty and Integrity</h2>
                        <p>We uphold the highest ethical standards, fostering trust and transparency in all our interactions, both internally and externally.</p>
                    </div>
                    <div className="columns above">
                        <h2><img src={A} alt="" />Adaptability</h2>
                        <p>We embrace change and innovation, constantly evolving to meet the dynamic needs of our clients and the financial industry.</p>
                    </div>
                    <div className="columns above">
                        <h2><img src={M} alt="" />Meticulousness</h2>
                        <p>We pay attention to detail, ensuring precision and accuracy in every financial solution we provide, no matter how complex.</p>
                    </div>
                    <div className="columns above">
                        <h2><img src={P} alt="" />Professionalism</h2>
                        <p>We maintain a high level of professionalism in all aspects of our work, from client relationships to internal collaborations, setting industry standards.</p>
                    </div>
                    <div className="columns above">
                        <h2> <img src={S} alt="" />Service Excellence</h2>
                        <p>We are committed to delivering excellence in every service we offer, going above and beyond to exceed customer expectations and create lasting relationships.</p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-content">
                    <p>At Champs Finance, these values form the foundation of our operations, guiding us in our mission to deliver best in class financial products and services that exceed expectations.</p>
                    <Link to="/contact" className="learn-more">Contact Us</Link>
                </div>
            </div>


            <Footer />
        </div>
    );
}

export default About;
