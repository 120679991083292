import React, { useState } from "react";
import Navbar from "./nav"; // Update the path as needed
import Footer from "./footer"; // Update the path as needed
import "./css/fx.css";
import Coin from './images/coingold.svg';
import Chart from './images/chart.png';
import Bulb from './images/bulb.png';
import Guy from './images/Guy.png';
import StopWatch from './images/stopwatch.png';
import Overlay from './images/card-imageback.png';
import Cancel from './images/cancel.png';
import axios from 'axios'; // Import Axios



function FxAdviosry() {
    const [isModalOpenJwt, setIsModalOpenJwt] = useState(false);
    const openModalJwt = () => {
        setIsModalOpenJwt(true);
    };

    // Function to close the modal
    const closeModalJwt = () => {
        setIsModalOpenJwt(false);
    };
    // State variables to store form input values
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Create an object with the form data
        const formData = {
            name: name,
            companyName: companyName,
            phone: phone,
            email: email,
        };

        // Make an HTTP POST request to your server-side script (API)
        axios.post('https://champsfin.com/champs/fx_call.php', formData)
            .then((response) => {
                setSuccessMessage("Form submitted successfully!");
                setErrorMessage(""); // Clear any previous error message
                console.log(response.data);
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage(""); // Clear any previous success message
                console.error(error);
            });
    };
    const clearFormFieldsAndMessages = () => {
        setName('');
        setCompanyName('');
        setPhone('');
        setEmail('');
    };
    return (
        <div>
            <div className="body" >
                <Navbar />
                <div className="heroo">

                    <div className="hero-text">
                        <h1>Champs Foreign
                            Exchange Advisory!</h1>
                        <p>Navigating the complex world of international transactions just got easier with Champs Finance. Our expert Foreign Exchange Advisory services are tailored to businesses and corporate organizations, ensuring you make informed decisions every step of the way.</p>
                    </div>
                    <div className="hero-image">
                        <img src={Coin} alt="Hero Image" />
                    </div>

                </div>


            </div>
            <div className="image-card-container">
                <div className="image-card">
                    <div className="image-card-background">
                        <img src={StopWatch} alt="Image 1" />
                    </div>
                    <div className="image-text-container">
                        <h3 className="image-text-h3">Expert Guidance</h3>
                        <p className="image-text-p"> Our seasoned advisors offer expert insights, helping
                            you save money and make strategic financial moves.</p>
                    </div>
                </div>
                <div className="image-card">
                    <div className="image-card-backgroundpl">
                        <img src={Bulb} alt="Image 1" />
                    </div>
                    <div className="image-text-container">
                        <h3 className="image-text-h3">Tailored Solutions</h3>
                        <p className="image-text-p">We customize FX agreements to suit your unique
                            needs, facilitating secure international payments
                            and fund access.</p>
                    </div>
                </div>

                <div className="image-card">
                    <div className="image-card-backgroundpll">
                        <img src={Chart} alt="Image 1" />
                    </div>
                    <div className="image-text-container">
                        <h3 className="image-text-h3">Focus on Your Business</h3>
                        <p className="image-text-p">With us by your side, you can concentrate on your
                            core operations, while we handle your foreign
                            exchange matters efficiently.</p>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <div class="image-with-overlay">
                    <img src={Overlay} alt="Image" />
                    <div class="overlay">
                        <h2>Request Your Free FX Advisory
                            Call Today!</h2>
                        <p>Ready to optimize your international financial strategies? Click below
                            to request a complimentary FX advisory call from our dedicated team.
                            Let's work together to enhance your business's financial efficiency and
                            global reach.</p>
                        <a className="overlay-learn-more" style={{ fontSize: `14px` }}  onClick={openModalJwt}>Request Call</a>

                    </div>
                </div>
                <img src={Guy} alt="Second Image" className="ima" />
            </div>
            <img src={Guy} alt="Second Image" className="imag" />

            <div className="card">
                <div className="card-content">
                    <p>🚀 Click to Request Your Free FX Advisory Call and Elevate Your International Transactions! 🚀</p>
                    <p>At Champs Finance, we're not just advisors; we're partners in your success story.
                        Experience the difference expertise can make.</p>
                    <a href="/contact" className="text-learn-more">Contact Us</a>
                </div>
            </div>


            <Footer />
            {isModalOpenJwt && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModalJwt} />
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Fx Call Request Form</h3>
                        </div>
                        <div>
                            {successMessage && <p className="success-message">{successMessage}</p>}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                        <form onSubmit={handleSubmit} className="jwm-form-loan">
                            <div>
                                <label htmlFor="fullName"> name</label>
                                <input type="text" id="fullName" placeholder="Enter full name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="fullName">Company name</label>
                                <input type="text" id="fullName" placeholder="Enter full name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="fullName">phone no</label>
                                <input type="tel" id="fullName" placeholder="Enter full name" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input type="email" id="email" placeholder="Enter your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <button type="submit">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FxAdviosry;
