import React from 'react'
import "../src/css/footer.css";
import Logo from './images/logo.png';
import Instagram from './images/instagram.png';
import Facebook from './images/facebook.png';
import Linkedln from './images/linkedln.png';
import X from './images/x.png';

export default function Footer() {
  return (

        <footer>
            <section className="footer-section">
                <img src={Logo} alt="" className="footer-logo" />
                <p className='footer-text'>
                At Champs Finance, these values form the foundation of our operations, guiding us in our mission to deliver best in class financial products and services that exceed expectations.</p>
                <div className="footer-socials">
                    <a href="https://instagram.com/champsfin?igshid=YTQwZjQ0NmI0OA==">
                        <img src={Instagram} alt="" />
                    </a>
                    <a href="#">
                        <img src={Facebook} alt="" />
                    </a>
                    <a href="#">
                        <img src={Linkedln} alt="" />
                    </a>
                    <a href="https://x.com/champsfin?s=21">
                        <img src={X} alt="" />
                    </a>
                </div>
            </section>
            <section className="footer-section">
                <p className="footer-section-subheading">
                    Legal
                </p>
                <a href="#" className='footer-link'>Terms & Condition</a>
                <a href="#" className='footer-link'>Privacy Policy</a>
            </section>
            <section className="footer-section">
                <p className="footer-section-subheading">
                    Services
                </p>
                <a href="/loan" className='footer-link'>Loans</a>
                <a href="/payment" className='footer-link'>Payment</a>
                <a href="/fx" className='footer-link'>Fx Advisory</a>
                <a href="/thrift" className='footer-link'>Thrifts</a>
            </section>
            <section className="footer-section">
                <p className="footer-section-subheading">
                    Contact Us
                </p>
                <a href="mailto:helloo@champsfin.com" className='footer-link'>hello@champsfin.com</a>
                <a href="#" className='footer-link'>09044000008, 08128042840, 09065556644</a>
                <a href="#" className='footer-link'>741, Adeola hopewell ST victoria island lagos.</a>
            </section>
        </footer>

  )
}
