import React, { useEffect } from 'react';
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos";
import './css/landing-page.css'
import Logo from './images/logo.png';
import Instagram from './images/instagram.png';
import Facebook from './images/facebook.png';
import Linkedln from './images/linkedln.png';
import X from './images/x.png';
import Fx from './images/fx.svg';
import Finance from './images/finance.svg';
import Loan from './images/loan.svg';
import Thrift from './images/Thrift.svg';
import { Link } from 'react-router-dom';



function LandingPage() {
  useEffect(() => {
    AOS.init(); // Initialize AOS inside the component
  }, []);
  return (
    <div className='intro-page'>
    <div className="money-bg">
      <nav className="intro-nav">
        <img src={Logo} alt="" className="landingpage-nav-logo" />
        <div className="intro-nav-social">
          <Link to=""> <img src={Instagram} alt="" /></Link>
         <Link to="">  <img src={Facebook} alt="" /></Link>
         <Link to="">  <img src={Linkedln} alt="" /></Link>
         <Link to="">   <img src={X} alt="" /></Link>
        </div>
      </nav>
      <main className='intro-main'>
        <h1>
          Champs Finance Limited
        </h1>
        <p>
          Empower your Financial Future with Champs finance.. Your Trusted Partner in Financial Success.
        </p>
        <Link to="/about" className="intro-btn">
          Champion your Future
        </Link>
      </main>
    
      <div className="champ-offers" >
        <Link to="/loan" > <img src={Loan} alt="" />
          <p>Apply for loans</p></Link>
       
        <Link to="/payment"> <img src={Finance} alt="" />
          <p>Finance Payments</p></Link>
       
       <Link to="/fx"> <img src={Fx} alt="" />
          <p>Fx Advisory</p></Link>
       
       <Link to="/thrift"> <img src={Thrift} alt="" />
          <p>Thrifts</p></Link> 
      </div>
    </div>
  </div>
      );
}

      export default LandingPage;
