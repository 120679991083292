import React, { useState } from 'react';
import "./css/navbar.css";
import Logo from './images/logo.png';
import Menu from './images/menu.svg';
import Cancel from './images/cancel.png';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [show, setTogg] = useState(false);
  const [active, setActive] = useState(false);

  // Create an object for interest form fields
  const [interestForm, setInterestForm] = useState({
    fullName: '',
    email: '',
    interest: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isModalOpenJwt, setIsModalOpenJwt] = useState(false);

  const openModalJwt = () => {
    setIsModalOpenJwt(true);
  };

  // Function to close the modal
  const closeModalJwt = () => {
    setIsModalOpenJwt(false);
  };

  // Function to handle form submission
  const submitInterestForm = (e) => {
    e.preventDefault();

    // Send a POST request to your PHP script
    axios.post('https://champsfin.com/champs/waitlist.php', interestForm)
      .then((response) => {
        setSuccessMessage("Waitlist Joined successfully!");
        setErrorMessage(""); // Clear any previous error message
        setTimeout(() => setSuccessMessage(null), 3000);
        console.log(response.data);

        // Clear the second form input fields
        setInterestForm({
          fullName: '',
          email: '',
          interest: '',
        });
      })
      .catch((error) => {
        setErrorMessage("An error occurred. Please try again later.");
        setSuccessMessage(""); // Clear any previous success message
        console.error(error);
      });
  };
  return (
    <div>
     <nav className="landingpage-nav">
  <NavLink to="/" className="nav-linkk" activeClassName="active">
    <img src={Logo} alt="" className="landingpage-nav-logo" />
  </NavLink>
  <div className="landingpage-submenu">
    <div className="landingpage-nav-links">
      <NavLink to="/loan" className="nav-linkk" activeClassName="active">
        Loans
      </NavLink>
      <NavLink to="/payment" className="nav-linkk" activeClassName="active">
        Payments
      </NavLink>
      <NavLink to="/fx" className="nav-linkk" activeClassName="active">
        FX Advisory
      </NavLink>
      <NavLink to="/thrift" className="nav-linkk" activeClassName="active">
        Thrifts
      </NavLink>
      <NavLink to="/about" className="nav-linkk" activeClassName="active">
        About Us
      </NavLink>
      <NavLink to="/contact" className="nav-linkk" activeClassName="active">
        Contact Us
      </NavLink>
    </div>
    <a href="#" className="nav-btn" onClick={openModalJwt}>
      Join Waitlist
    </a>
  </div>
  <img src={Menu} alt="" className="ham-btn" onClick={() => setTogg(true)} />
</nav>
      {show ? (
        <div className="mobile-nav">
          <div className="mobile-nav-subheading">
            <img src={Logo} alt="" className="landingpage-nav-logo" />
            <div className="close-btn" onClick={() => setTogg(false)}>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="mn-landingpage-submenu">
            <div className="landingpage-nav-links">
              <Link to="/loan" className="mn-nav-linkk">Loans</Link>
              <Link to="/payment" className="mn-nav-linkk">Payments</Link>
              <Link to="/fx" className="mn-nav-linkk">FX Advisory</Link>
              <Link to="/thrift" className="mn-nav-linkk">Thrifts</Link>
              <Link to="/about" className="mn-nav-linkk">About Us</Link>
              <Link to="/contact" className="mn-nav-linkk">Contact Us</Link>
            </div>
            <a href="#" className="nav-btn" onClick={openModalJwt}>
              Join Waitlist
            </a>
          </div>
          <p className="copyright-text">
            &copy; 2023 ChampsFinance All Rights Reserved
          </p>
        </div>
      ) : null}
      {isModalOpenJwt && (
        <div className="modal-overlay">
          <div className="join-waitlist-modal-loan">
            <div className="modal-close">
              <img src={Cancel} alt="" onClick={closeModalJwt} />
            </div>
            <div className="jwm-subheading-loan">
              <h3>Join Our Waitlist</h3>
            </div>
            {/* Display success message */}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {/* Display error message */}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form onSubmit={submitInterestForm} className="jwm-form-loan">
              <div>
                <label htmlFor="fullName">Full name</label>
                <input
                  type="text"
                  id="fullName"
                  placeholder="Enter full name"
                  value={interestForm.fullName}
                  onChange={(e) =>
                    setInterestForm({ ...interestForm, fullName: e.target.value })
                  }
                />
              </div>
              <div>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your Email Address"
                  value={interestForm.email}
                  onChange={(e) =>
                    setInterestForm({ ...interestForm, email: e.target.value })
                  }
                />
              </div>
              <div>
                <label htmlFor="interest">Interest</label>
                <select
                  name="interest"
                  id="interest"
                  value={interestForm.interest}
                  onChange={(e) =>
                    setInterestForm({ ...interestForm, interest: e.target.value })
                  }
                >
                  <option selected disabled value="">
                    What service are you interested in?
                  </option>
                  <option value="Food loan">Food loan</option>
                  <option value="Fx Advisory">Fx Advisory</option>
                  <option value="Thrift">Thrift</option>
                </select>
              </div>
              <div style={{ marginTop: '30px' }}>
                <button type="submit">Submit</button>
              </div>

            </form>
          </div>
        </div>
      )}
    </div>
  );
}
