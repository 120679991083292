import React, { useState, useEffect } from "react";
import Navbar from "./nav";
import Footer from "./footer";
import "./css/payment.css";
import Inventory from './images/inventory.svg';
import GuyImage from './images/guyimage.svg';
import Pos from './images/posfone.svg';
import People from './images/people.svg';
import Barcode from './images/barcode.svg';
import Overlay from './images/card-imageback.png';
import Cancel from './images/cancel.png';
import axios from 'axios'; // Import Axios



function Payment() {
    const [isModalOpenJwt, setIsModalOpenJwt] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const openModalJwt = () => {
        setIsModalOpenJwt(true);
    };

    // Function to close the modal
    const closeModalJwt = () => {
        setIsModalOpenJwt(false);
    };
    const [interestForm, setInterestForm] = useState({
        fullName: '',
        email: '',
        interest: '',
    });

    const submitInterestForm = (e) => {
        e.preventDefault();

        // Send a POST request to your PHP script
        axios.post('https://champsfin.com/champs/waitlist.php', interestForm)
            .then((response) => {
                setSuccessMessage("Interest Form Submitted Successfully!");
                setErrorMessage(""); // Clear any previous error message
                setTimeout(() => setSuccessMessage(null), 3000);
                console.log(response.data);

                // Clear the second form input fields
                setInterestForm({
                    fullName: '',
                    email: '',
                    interest: '',
                });
            })
            .catch((error) => {
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage(""); // Clear any previous success message
                console.error(error);
            });
    };
    return (
        <div>
            <div className="body" >
                <Navbar />
                <div className="herooo">

                    <div className="herooo-text">
                        <h1>Champs Finance
                            Payments - Transforming
                            Your Payment Experience!</h1>
                        <p>Are you tired of waiting in long queues and dealing with cumbersome payment processes? Get ready for a game-changing solution! At Champs Finance, we're crafting an unprecedented payment experience that simplifies shopping, reduces time and costs for businesses and individuals alike, and offers tailored functionalities to meet your unique needs.</p>
                    </div>
                    <div className="herooo-image">
                        <img src={Inventory} alt="Hero Image" />
                    </div>

                </div>


            </div>
            <div className="header-title"> <h2>What Sets Champs Finance Payments Apart?</h2></div>
            <div className="image-card-container-payment">


                <div className="image-card-payment">
                    <img src={GuyImage} alt="Image 1" />
                    <div className="image-text-container-payment">
                        <h3 className="image-text-h3">Seamless Shopping</h3>
                        <p className="image-text-p">Say goodbye to queues and hassles! Our innovative solution ensures a smooth and hassle-free payment experience, enhancing your shopping joy.</p>
                    </div>
                </div>
                <div className="image-card-payment">
                    <img src={Pos} alt="Image 1" />
                    <div className="image-text-container-payment">
                        <h3 className="image-text-h3">Cost-Effective Solutions</h3>
                        <p className="image-text-p">Businesses and individuals, save big on transaction costs! Our payment system is designed to be budget-friendly, putting more money back in your pocket.</p>
                    </div>
                </div>

                <div className="image-card-payment">
                    <img src={Barcode} alt="Image 1" />
                    <div className="image-text-container-payment">
                        <h3 className="image-text-h3">Customized Functionalities</h3>
                        <p className="image-text-p">Tailor your payment experience! We offer customizable functionalities catering to the specific needs of both businesses and individuals, making every transaction uniquely yours.</p>
                    </div>
                </div>
            </div>
            <div class="image-container-payment">
                <img src={People} alt="Second Image" />
                <div class="image-with-overlay-payment">
                    <img src={Overlay} alt="Image" />
                    <div class="overlay-payment">
                        <h2>Join the Queueless Pay Revolution -
                            Get on the Waitlist!</h2>
                        <p>Excited to experience the future of payments? Join our waitlist and be the
                            first to embrace a queueless, efficient, and personalized payment journey.
                            Click below to join, and get ready to transform how you pay!</p>
                        <a className="overlay-learn-more-payment" onClick={openModalJwt} >Join Waitlist</a>

                    </div>
                </div>

            </div>

            <div className="card">
                <div className="card-content">
                    <p>🚀 Click to Join the Waitlist and Embrace the Future of Payments! 🚀</p>
                    <p>At Champs Finance Payments, we're redefining the way you pay. Get ready to
                        shop smarter, faster, and with tailored convenience</p>
                    <button className="text-learn-more" onClick={openModalJwt} >Join Waitlist</button>
                </div>
            </div>


            <Footer />
            {isModalOpenJwt && (
                <div className="modal-overlay">
                    <div className="join-waitlist-modal-loan">
                        <div className="modal-close">
                            <img src={Cancel} alt="" onClick={closeModalJwt} />
                        </div>
                        <div className="jwm-subheading-loan">
                            <h3>Join Our Waitlist</h3>
                        </div>
                        {/* Display success message */}
                        {successMessage && <div className="success-message">{successMessage}</div>}

                        {/* Display error message */}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <form onSubmit={submitInterestForm} className="jwm-form-loan">
                            <div>
                                <label htmlFor="fullName">Full name</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter full name"
                                    value={interestForm.fullName}
                                    onChange={(e) => setInterestForm({ ...interestForm, fullName: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your Email Address"
                                    value={interestForm.email}
                                    onChange={(e) => setInterestForm({ ...interestForm, email: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="interest">Interest</label>
                                <select
                                    name="interest"
                                    id="interest"
                                    value={interestForm.interest}
                                    onChange={(e) => setInterestForm({ ...interestForm, interest: e.target.value })}
                                >
                                    <option selected disabled value="">What service are you interested in?</option>
                                    <option value="Food loan">Food loan</option>
                                    <option value="Fx Advisory">Fx Advisory</option>
                                    <option value="Thrift">Thrift</option>
                                </select>
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <button type="submit">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Payment;
